@import url('https://www.cufonfonts.com/font/fs-elliot-pro');
/* oswald-300 - latin */

/* Moving google fonts to being hosted locally */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 400;
     src: local(''), url('./assets/fonts/Roboto-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
     font-family: 'Roboto';
     font-style: bold;
     font-weight: 500;
     src: local(''), url('./assets/fonts/Roboto-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* FS Elliot pro bold */
@font-face {
     font-family: 'fs-elliot-pro-bold';
     font-style: normal;
     font-weight: 300;
     src: url('./assets//fonts/fs-elliot-pro/FS\ Elliot\ ProBold.otf'); /* IE9 Compat Modes */
}

/* FS Elliot pro light */
@font-face {
     font-family: 'fs-elliot-pro-light';
     font-style: normal;
     font-weight: 300;
     src: url('./assets//fonts/fs-elliot-pro/FS\ Elliot\ ProLight.otf'); /* IE9 Compat Modes */
}

/* FS Elliot pro Regular */
@font-face {
     font-family: 'fs-elliot-pro-regular';
     font-style: normal;
     font-weight: 300;
     src: url('./assets//fonts/fs-elliot-pro/FS\ Elliot\ ProRegular.otf'); /* IE9 Compat Modes */
}

@font-face {
     font-family: 'Oswald';
     font-style: normal;
     font-weight: 300;
     src: url('./assets/fonts/oswald-v36-latin-300.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('./assets/fonts/oswald-v36-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('./assets/fonts/oswald-v36-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-300.woff') format('woff'), /* Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('./assets/fonts/oswald-v36-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

  /* oswald-regular - latin */
@font-face {
     font-family: 'Oswald-Regular';
     font-style: normal;
     font-weight: 400;
     src: url('./assets/fonts/oswald-v36-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('./assets/fonts/oswald-v36-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('./assets/fonts/oswald-v36-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('./assets/fonts/oswald-v36-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}