@import url(https://www.cufonfonts.com/font/fs-elliot-pro);
/* oswald-300 - latin */

/* Moving google fonts to being hosted locally */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 400;
     src: local(''), url(/static/media/Roboto-Regular.fc2b5060.ttf) format('truetype'); /* Safari, Android, iOS */
}
@font-face {
     font-family: 'Roboto';
     font-style: bold;
     font-weight: 500;
     src: local(''), url(/static/media/Roboto-Medium.7c8d04cd.ttf) format('truetype'); /* Safari, Android, iOS */
}

/* FS Elliot pro bold */
@font-face {
     font-family: 'fs-elliot-pro-bold';
     font-style: normal;
     font-weight: 300;
     src: url("/static/media/FS Elliot ProBold.60e3e341.otf"); /* IE9 Compat Modes */
}

/* FS Elliot pro light */
@font-face {
     font-family: 'fs-elliot-pro-light';
     font-style: normal;
     font-weight: 300;
     src: url("/static/media/FS Elliot ProLight.40d1f0cd.otf"); /* IE9 Compat Modes */
}

/* FS Elliot pro Regular */
@font-face {
     font-family: 'fs-elliot-pro-regular';
     font-style: normal;
     font-weight: 300;
     src: url("/static/media/FS Elliot ProRegular.bc5edfe6.otf"); /* IE9 Compat Modes */
}

@font-face {
     font-family: 'Oswald';
     font-style: normal;
     font-weight: 300;
     src: url(/static/media/oswald-v36-latin-300.8ba88fbe.eot); /* IE9 Compat Modes */
     src: local(''),
          url(/static/media/oswald-v36-latin-300.8ba88fbe.eot?#iefix) format('embedded-opentype'), 
          url(/static/media/oswald-v36-latin-300.6baac703.woff2) format('woff2'), 
          url(/static/media/oswald-v36-latin-300.6397ef09.woff) format('woff'), 
          url(/static/media/oswald-v36-latin-300.5dfef84b.ttf) format('truetype'), 
          url(/static/media/oswald-v36-latin-300.d52401ae.svg#Oswald) format('svg'); /* Legacy iOS */
}

  /* oswald-regular - latin */
@font-face {
     font-family: 'Oswald-Regular';
     font-style: normal;
     font-weight: 400;
     src: url(/static/media/oswald-v36-latin-regular.6e41c761.eot); /* IE9 Compat Modes */
     src: local(''),
          url(/static/media/oswald-v36-latin-regular.6e41c761.eot?#iefix) format('embedded-opentype'), 
          url(/static/media/oswald-v36-latin-regular.5ee68289.woff2) format('woff2'), 
          url(/static/media/oswald-v36-latin-regular.3d1d485b.woff) format('woff'), 
          url(/static/media/oswald-v36-latin-regular.bd101345.ttf) format('truetype'), 
          url(/static/media/oswald-v36-latin-regular.998bcd1c.svg#Oswald) format('svg'); /* Legacy iOS */
}
